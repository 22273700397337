import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Sketch from '../../components/sketch';
import sketchOptVal from '../../scripts/sOptVal/sOptVal';
import Layout from '../../components/layout';
import DateConverter from '../../components/dateConverter';
export const _frontmatter = {
  "title": "Optimal value fn",
  "date": "2021-01-05",
  "slug": "optval",
  "author": "JAN MALTE LICHTENBERG"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Layout mdxType="Layout">
      <div>
        <DateConverter frontmatter={props.pageContext.frontmatter} mdxType="DateConverter" />
        <p className="blogtitle">
          {props.pageContext.frontmatter.title}
        </p>
      </div>
      <p>{`bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla`}</p>
      <Sketch sketch={sketchOptVal} mdxType="Sketch" />
      <p>{`bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla
bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla`}</p>
    </Layout>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      